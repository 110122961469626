import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateFilter = ({ startDate, endDate, onDateChange }) => {
  return (
    <div className="d-flex justify-content-end mb-3">
      <div className="me-2">
        <DatePicker
          showIcon
          selected={startDate}
          onChange={(date) => onDateChange(date, "startDate")}
          dateFormat="dd-MM-yyyy" 
          placeholderText="Start Date"
        />
      </div>
      <div>
        <DatePicker
          showIcon
          selected={endDate}
          onChange={(date) => onDateChange(date, "endDate")}
          dateFormat="dd-MM-yyyy" 
          placeholderText="End Date"
        />
      </div>
    </div>
  );
};

export default DateFilter;
