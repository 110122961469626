import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { getCurrentToken } from "../../services/cognito/cognitoAuth";
import Select from "react-select";
import ProjectContext from "../../ProjectContext";

function AddProject({ handleClose, triggerRefresh }) {
  const [projectName, setProjectName] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [pageSize, setPageSize] = useState();
  const [projectNameError, setProjectNameError] = useState("");
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);
  const { groupId, userId, tenantId, roles } = useContext(ProjectContext);
  const [tenants, setTenants] = useState([]);
  // const [selectedTenant, setSelectedTenant] = useState(null);
  // const [tenantNameError, setTenantNameError] = useState("");
  const [tags, setTags] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectType, setProjectType] = useState("");
  const [projectTypeError, setProjectTypeError] = useState("");
  const [previewProjects, setPreviewProjects] = useState([]);
  const [selectedPreviewProject, setSelectedPreviewProject] = useState(null);

  const ProjectType = [
    { value: "PREVIEW_PROJECT", label: "Preview Project" },
    { value: "PROCESS_PROJECT", label: "Process Project" },
  ];

  useEffect(() => {
    // Fetch users
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}user/list`,
          {
            headers: {
              Authorization: `Bearer ${await getCurrentToken()}`,
            },
            params: {
              page: 1,
              size: "", // use pageSize
              search: "",
              status: "ACTIVE",
              sortColumn: "id",
              sortOrder: "DESC",
              group: "",
              logginGroupId: groupId,
              userId: userId,
              tenantId: tenantId,
            },
          }
        );
        if (response.status === 200) {
          setUsers(
            response.data.content
              .filter((user) => !user.userGroups.includes("SuperAdmin"))
              .map((user) => ({
                value: user.id,
                label: user.firstName + " " + user.lastName,
                // label:
                //   user.firstName +
                //   " " +
                //   user.lastName +
                //   " (" +
                //   user.userGroups +
                //   ")",
              }))
          );
        } else {
          console.error("Error fetching users:", response);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchModels = async () => {
      setLoading(true);
      try {
        const token = await getCurrentToken();

        if (!token) {
          console.error("No token found");
          return;
        }
        const params = {
          page: 1,
          size: "",
          search: "",
          status: "ACTIVE",
          sortColumn: "id",
          sortOrder: "DESC",
        };

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}model/list`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: params,
          }
        );

        setModels(response.data.content);
      } catch (error) {
        console.error("Error fetching model data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchModels();
  }, []);

  const fetchTenants = async () => {
    setLoading(true);
    try {
      const token = await getCurrentToken();

      if (!token) {
        console.error("No token found");
        return;
      }
      const params = {
        page: 1,
        size: "",
        sortColumn: "name",
        sortOrder: "ASC",
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}tenant/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: params,
        }
      );

      const tenantsData = response.data.content.map((tenant) => ({
        value: tenant.id,
        label: tenant.name,
      }));

      setTenants(tenantsData);
    } catch (error) {
      console.error("Error fetching model data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTenants();
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!projectName) {
      setProjectNameError("Project Name is required");
      return;
    }
    if (!projectType) {
      setProjectTypeError("Project Type is required");
      return;
    }
    // Check if a project with the same name exists
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}project/list`,
        {
          params: {
            page: 1,
            size: 1000,
            search: "",
            status: "",
            sortColumn: "name",
            sortOrder: "DESC",
          },
          headers: {
            Authorization: `Bearer ${await getCurrentToken()}`,
          },
        }
      );
      if (
        response.data.content.some((project) => project.name === projectName)
      ) {
        setProjectNameError("A project with this name already exists");
        return;
      }
    } catch (error) {
      console.error("Error checking project name:", error);
    }
    try {
      const requestBody = {
        name: projectName,
        tenantId: tenantId,
        projectType: projectType,
      };

      if (selectedModel) {
        requestBody.modelId = selectedModel.value;
      }
      if (selectedProject) {
        requestBody.parentProjectId = selectedProject.value;
        requestBody.copyTitle = true;
        requestBody.copyPresetTag = true;
      }
      if (selectedPreviewProject) {
        requestBody.previewProjectName = selectedPreviewProject;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}project`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${await getCurrentToken()}`,
          },
        }
      );

      if (response.status === 200) {
        const projectId = response.data.id;
        if (selectedUsers.length > 0) {
          await addUsersToProject(projectId);
        }
        triggerRefresh(); // refresh the project list
        handleClose(); // close the modal
      } else {
        console.error("Error adding project:", response);
      }
    } catch (error) {
      console.error("Error adding project:", error);
    }
  };
  const addUsersToProject = async (projectId) => {
    try {
      const newUsers = selectedUsers.map((user) => ({
        value: user.value,
        label: user.label,
        // role: user.label.split(" ").pop().slice(1, -1),
        role: "ProjectAdmin",
      }));
      const userGroups = newUsers.map((user) => {
        // let groupId;
        // switch (user.role) {
        //   case "SuperAdmin":
        //     groupId = 1;
        //     break;
        //   case "Reviewer":
        //     groupId = 2;
        //     break;
        //   case "ProjectAdmin":
        //     groupId = 3;
        //     break;
        //   case "TenantAdmin":
        //     groupId = 4;
        //     break;
        //   case "Developer":
        //     groupId = 5;
        //     break;
        //   default:
        //     groupId = null;
        // }

        return {
          userId: user.value,
          tenantId: tenantId,
          projectId: projectId,
          groupId: 3,
          status: "ACTIVE",
        };
      });
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}user/groups`,
        userGroups,
        {
          headers: {
            Authorization: `Bearer ${await getCurrentToken()}`,
          },
        }
      );
      if (response.status !== 200) {
        console.error("Error adding users to project:", response);
      }
    } catch (error) {
      console.error("Error adding users to project:", error);
    }
  };
  // const fetchTags = async () => {
  //   setLoading(true);
  //   try {
  //     const token = await getCurrentToken();

  //     const params = {
  //       page: 1,
  //       size: "",
  //       name: "",
  //       sortColumn: "id",
  //       sortOrder: "DESC",
  //       status: "",
  //       userId: userId,
  //       role: "ProjectAdmin",
  //     };

  //     const response = await axios.get(
  //       `${process.env.REACT_APP_BACKEND_URL}preset-tag/list`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //         params: params,
  //       }
  //     );

  //     if (response.status === 200) {
  //       const tags = response.data.content;
  //       setTags(tags);

  //       // Extract unique projects
  //       const uniqueProjects = [];
  //       const projectIds = new Set();

  //       tags.forEach((tag) => {
  //         const project = tag.project;
  //         if (
  //           project &&
  //           project.status === "ACTIVE" &&
  //           !projectIds.has(project.id)
  //         ) {
  //           projectIds.add(project.id);
  //           uniqueProjects.push(project);
  //         }
  //       });

  //       setProjects(uniqueProjects);
  //     } else {
  //       console.error("Error fetching projects:", response);
  //     }
  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching issues:", error);
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchTags();
  // }, []);
  const fetchProjects = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}project/list`,
        {
          params: {
            page: 1,
            size: "",
            search: "",
            status: "ACTIVE",
            sortColumn: "name",
            sortOrder: "DESC",
            //projectType: "PREVIEW_PROJECT",
            tenantId: tenantId,
          },
          headers: {
            Authorization: `Bearer ${await getCurrentToken()}`,
          },
        }
      );
      const jsonData = response.data;
      if (Array.isArray(jsonData.content)) {
        const previewProjects = jsonData.content
          .filter((project) => project.projectType === "PREVIEW_PROJECT")
          .map((project) => ({
            value: project.id,
            label: project.name,
          }));
        setPreviewProjects(previewProjects);
  
        const allProjects = jsonData.content.map((project) => ({
          value: project.id,
          label: project.name,
        }));
        setProjects(allProjects);
      } else {
        console.error("Invalid data format: content is not an array");
      }
    } catch (error) {
      console.error("Error checking project name:", error);
    }
  };
  useEffect(() => {
    fetchProjects();
  }, []);
  return (
    <div>
      <Modal.Header>
        <Modal.Title>Add Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label>Project Name</label>
            <input
              type="text"
              className="form-control"
              name="projectName"
              value={projectName}
              onChange={(e) => {
                setProjectName(e.target.value);
                setProjectNameError("");
              }}
            />
            {projectNameError && (
              <div className="text-danger">{projectNameError}</div>
            )}
          </div>
          <div className="mb-3">
            <label>Project Type</label>
            <Select
              options={ProjectType}
              name="projectType"
              className="basic-select"
              classNamePrefix="select"
              onChange={(e) => {
                setProjectType(e.value);
                setProjectTypeError("");
              }}
            />
            {projectTypeError && (
              <div className="text-danger">{projectTypeError}</div>
            )}
          </div>
          {projectType === "PROCESS_PROJECT" && (
            <div className="mb-3">
              <label>Preview Project File Import</label>
              <Select
                isClearable
                name="projects"
                options={previewProjects}
                className="basic-select"
                classNamePrefix="select"
                onChange={(e) => {
                  if (e) {
                    setSelectedPreviewProject(e.label);
                  } else {
                    setSelectedPreviewProject(null);
                  }
                }}
              />
            </div>
          )}
          {/* <div className="mb-3">
            <label>Select Tenant</label>
            <Select
              options={tenants}
              value={selectedTenant}
              onChange={(e) => {
                setSelectedTenant(e);
                setTenantNameError("");
              }}
            />
            {tenantNameError && (
              <div className="text-danger">{tenantNameError}</div>
            )}
          </div> */}
          <div className="mb-3">
            <label>Select Project Admin</label>
            <Select
              isMulti
              name="users"
              options={users}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={setSelectedUsers}
            />
          </div>
          <div className="mb-3">
            <label>Select Model</label>
            <Select
              isClearable
              name="models"
              options={models.map((model) => ({
                value: model.id,
                label: model.name,
              }))}
              className="basic-select"
              classNamePrefix="select"
              onChange={setSelectedModel}
            />
          </div>
          <div className="mb-3">
            <label>Copy Issue Titles and PresetTags from Project</label>
            <Select
              isClearable
              name="projects"
              options={projects}
              className="basic-select"
              classNamePrefix="select"
              onChange={setSelectedProject}
            />
          </div>
          <div className="mb-3 text-end">
            <button
              type="button"
              className="btn btn-secondary me-2"
              onClick={handleClose}
            >
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Save Changes
            </button>
          </div>
        </form>
      </Modal.Body>
    </div>
  );
}

export default AddProject;
