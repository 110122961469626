import React, { useState, useEffect, useContext } from "react";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import "chart.js/auto";
import { getCurrentToken } from "../../services/cognito/cognitoAuth";
import Select from "react-select";
import ProjectContext from "../../ProjectContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateFilter from "./DateFilter";

function TotalReviewsAssignees({ title, startDate, endDate }) {
  const [chartData, setChartData] = useState(null);
  const [titles, setTitles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const typeOptions = [
    { value: "SECTION", label: "Section" },
    { value: "SUBSECTION", label: "Subsection" },
    { value: "ISSUE_NATURE", label: "Issue Nature" },
    { value: "ISSUE_TYPE", label: "Issue Type" },
    {
      value: "TEMPLATE_CHANGE_TYPE",
      label: "Template change type",
    },
    { value: "TAG", label: "Tag" },
  ];
  const [filterType, setFilterType] = useState(typeOptions[0]);
  const { tenantId, groupId, userId, projectId, roles } =
    useContext(ProjectContext);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [filterCreatedBy, setFilterCreatedBy] = useState("");
  

  const fetchUsers = async () => {
    try {
      const token = await getCurrentToken();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}user/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page: 1,
            size: "",
            search: "",
            status: "",
            sortColumn: "id",
            sortOrder: "DESC",
            group: "Reviewer",
            projectId: projectId,
            logginGroupId: groupId,
            tenantId: tenantId,
            userId: userId,
          },
        }
      );
      const users = response.data.content;

      setUsers(users);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  const fetchData = async () => {
    const token = await getCurrentToken();
    try {
      const userIdsParam =
        selectedUsers.map((user) => user.value).join(",") ||
        filterCreatedBy ||
        "";
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}dashboard/assignee-reviews?`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            userIds: userIdsParam,
            projectId: projectId,
            startDate: startDate.toISOString().split("T")[0] + " 00:00:00",
            endDate: endDate.toISOString().split("T")[0] + " 00:00:00",
          },
        }
      );

      const data = response.data;
      setChartData({
        labels: data.map((item) => item.userName),
        datasets: [
          {
            label: "Number of Reviews",
            data: data.map((item) => item.fileCount),
            backgroundColor: ["#4e73df"],
            borderWidth: 1,
            //barThickness: 20,
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (roles.includes("ProjectAdmin")) {
      fetchUsers();
    }
  }, [projectId, groupId, tenantId, userId, roles]);

  useEffect(() => {
    fetchData();
  }, [projectId, startDate, endDate, selectedUser, filterCreatedBy]);
  return (
    <div>
      <h5 className="fw-bold">Total Number of Reviews for all Assignees</h5>
      <div className="row g-2 mb-3">
        <div className="col-md-12">
          <Select
            options={users.map((user) => ({
              value: user.id,
              label: `${user.firstName} ${user.lastName}`,
            }))}
            value={selectedUsers}
            isClearable
            isMulti
            placeholder="Select User"
            className=""
            onChange={(selectedOptions) => {
              setSelectedUsers(selectedOptions || []);
              setFilterCreatedBy(
                selectedOptions
                  ? selectedOptions.map((option) => option.value).join(",")
                  : ""
              );
            }}
          />
        </div>
      </div>

      {chartData ? (
        <Bar
          data={chartData}
          options={{
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
              datalabels: {
                color: "#fff", // Set the color of the label
                align: "start",
                anchor: "end",
              },
            },
            scales: {
              y: {
                beginAtZero: true,
                title: {
                  display: true,
                  text: "Count",
                },
                grid: {
                  display: false,
                },
              },
              x: {
                title: {
                  display: true,
                  text: "Users",
                },
                grid: {
                  display: false,
                },
                ticks: {
                  display: false,
                },
              },
            },
          }}
        />
      ) : (
        <p className="text-center">Loading data...</p>
      )}
    </div>
  );
}

export default TotalReviewsAssignees;
