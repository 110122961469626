import React, { useState, useEffect, useContext } from "react";
import { Pie } from "react-chartjs-2";
import axios from "axios";
import "chart.js/auto";
import { getCurrentToken } from "../../services/cognito/cognitoAuth";
import ProjectContext from "../../ProjectContext";
import Select from "react-select";

function IssueNoissueCountChart({ startDate, endDate }) {
  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { tenantId, groupId, userId, projectId, roles } =
    useContext(ProjectContext);
  
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [filterCreatedBy, setFilterCreatedBy] = useState("");
  const fetchUsers = async () => {
    try {
      const token = await getCurrentToken();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}user/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page: 1,
            size: "",
            search: "",
            status: "",
            sortColumn: "id",
            sortOrder: "DESC",
            group: "Reviewer",
            projectId: projectId,
            logginGroupId: groupId,
            tenantId: tenantId,
            userId: userId,
          },
        }
      );
      const users = response.data.content;

      setUsers(users);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  const fetchData = async () => {
    const token = await getCurrentToken();
    try {
      const userRole = roles.includes("Reviewer") ? "Reviewer" : "Other";
      const userIdsParam =
        userRole === "Reviewer"
          ? userId
          : filterCreatedBy || selectedUser?.value;
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}dashboard/issue-noissue-count`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            projectId: projectId,
            startDate: startDate.toISOString().split("T")[0] + " 00:00:00",
            endDate: endDate.toISOString().split("T")[0] + " 00:00:00",
            userIds: userIdsParam,
          },
        }
      );

      const data = response.data;
      const issueData = data.find((item) => item.status === "ISSUE");
      const noIssueData = data.find((item) => item.status === "noissue");

      const issueFileCount = issueData ? issueData.count : 0;
      const noIssueFileCount = noIssueData ? noIssueData.count : 0;

      setChartData({
        labels: ["Issue File Count", "No Issue File Count"],
        datasets: [
          {
            label: "Count",
            data: [issueFileCount, noIssueFileCount],
            backgroundColor: ["#4e73df", "#1bc48c"],
            borderWidth: 1,
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (roles.includes("ProjectAdmin")) {
      fetchUsers();
    }
  }, [projectId, groupId, tenantId, userId, roles]);
  useEffect(() => {
    fetchData();
  }, [projectId, startDate, endDate, selectedUser, filterCreatedBy]);

  return (
    <div>
      <h5 className="fw-bold">Issues/No Issues Count</h5>
      
      {roles.includes("ProjectAdmin") && (
        <div className="mt-2">
          <Select
            options={users.map((user) => ({
              value: user.id,
              label: `${user.firstName} ${user.lastName}`,
            }))}
            value={selectedUser}
            isClearable
            placeholder="Select User"
            className=""
            onChange={(selectedOption) => {
              setSelectedUser(selectedOption);
              setFilterCreatedBy(selectedOption ? selectedOption.value : "");
            }}
          />
        </div>
      )}
      {chartData ? (
        <div className="d-flex justify-content-center p-3">
          <Pie
            data={chartData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  display: true,
                  position: "top",
                },
                datalabels: {
                  color: "#fff", // Set the color of the label
                },
              },
            }}
          />
        </div>
      ) : (
        <p className="text-center">Loading data...</p>
      )}
    </div>
  );
}

export default IssueNoissueCountChart;
