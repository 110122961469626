import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import {
  completeNewPasswordChallenge,
  changePassword,
  fetchUserRoles,
  signOut,
  getCurrentUser,
} from "../../services/cognito/cognitoAuth";
import AuthContext from "../auth/AuthContext";
import axios from "axios";
import { EyeFill, EyeSlashFill } from "react-bootstrap-icons";

function ChangePassword() {
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [roles, setRoles] = useState([]);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const { setAuthenticated } = useContext(AuthContext);
  const [showPasswords, setShowPasswords] = useState([false, false, false]);
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      if (newPassword !== confirmPassword) {
        throw new Error("Passwords do not match.");
      }
      let cognitoUser = window.currentUser;

      if (window.newPasswordRequired) {
        await completeNewPasswordChallenge(cognitoUser, newPassword);
        window.newPasswordRequired = false;
        setIsPasswordChanged(true);

        const token = cognitoUser.signInUserSession.accessToken.jwtToken;
        const cognitoUsername = cognitoUser.username;
        await axios.put(
          `${process.env.REACT_APP_BACKEND_URL}/user/status-update`,
          {
            cognitoUsername: cognitoUsername,
            status: "ACTIVE",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        await changePassword(oldPassword, newPassword);
        navigate("/home");
      }
    } catch (err) {
      console.log(err);
      setError(err.message);
    }
  };

  useEffect(() => {
    if (!window.newPasswordRequired) {
      fetchUserRoles().then((fetchedRoles) => {
        setRoles(fetchedRoles);
      });
    }
  }, []);

  const handleSignOut = async () => {
    await signOut();
    navigate("/login");
    setAuthenticated(false);
  };

  const toggleShowPassword = (index) => {
    const newShowPasswords = [...showPasswords];
    newShowPasswords[index] = !newShowPasswords[index];
    setShowPasswords(newShowPasswords);
  };

  return (
    <>
      <h2 className="text-center mb-4 fw-bold">Change Password</h2>
      {isPasswordChanged && (
        <div className="text-success text-center mt-2">
          Password changed successfully.{" "}
          <Link onClick={handleSignOut}>Login again</Link>
        </div>
      )}
      {!isPasswordChanged && (
        <form onSubmit={handleSubmit}>
          {!window.newPasswordRequired && (
            <div className="form-floating position-relative mb-3">
              <input
                type={showPasswords[0] ? "text" : "password"}
                className="form-control rounded-0"
                id="oldPassword"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                placeholder="Old Password"
              />
              <label htmlFor="oldPassword">Old Password</label>
              <span
                className="position-absolute top-50 end-0 translate-middle-y me-3 text-muted"
                onClick={() => toggleShowPassword(0)}
                style={{ cursor: "pointer" }}
              >
                {showPasswords[0] ? (
                  <EyeSlashFill size={20} />
                ) : (
                  <EyeFill size={20} />
                )}
              </span>
            </div>
          )}
          <div className="form-floating position-relative mb-3">
            <input
              type={showPasswords[1] ? "text" : "password"}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="form-control rounded-0"
              id="newPassword"
              placeholder="New Password"
            />
            <label htmlFor="newPassword">New Password</label>
            <span
              className="position-absolute top-50 end-0 translate-middle-y me-3 text-muted"
              onClick={() => toggleShowPassword(1)}
              style={{ cursor: "pointer" }}
            >
              {showPasswords[1] ? (
                <EyeSlashFill size={20} />
              ) : (
                <EyeFill size={20} />
              )}
            </span>
          </div>
          <div className="form-floating position-relative mb-3">
            <input
              type={showPasswords[2] ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="form-control rounded-0"
              id="confirmPassword"
              placeholder="Confirm Password"
            />
            <label htmlFor="confirmPassword">Confirm Password</label>
            <span
              className="position-absolute top-50 end-0 translate-middle-y me-3 text-muted"
              onClick={() => toggleShowPassword(2)}
              style={{ cursor: "pointer" }}
            >
              {showPasswords[2] ? (
                <EyeSlashFill size={20} />
              ) : (
                <EyeFill size={20} />
              )}
            </span>
          </div>
          <div className="alert alert-info">
            Password must be at least 8 characters long and contain at least one
            uppercase letter, one lowercase letter, one number, and one special
            character.
          </div>
          <button className="btn btn-primary w-100" type="submit">
            Change Password
          </button>
          {error && <div className="text-danger text-center mt-2">{error}</div>}
          {!window.newPasswordRequired && (
            <div className="text-center mt-2">
              {roles.includes("SuperAdmin") ? (
                <Link to="/users">Back to users</Link>
              ) : (
                <Link to="/home">Back to home</Link>
              )}
            </div>
          )}
        </form>
      )}
    </>
  );
}

export default ChangePassword;
