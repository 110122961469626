import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import { getCurrentToken } from "../../services/cognito/cognitoAuth";
import ProjectContext from "../../ProjectContext";
import { PlusCircle, Trash3 } from "react-bootstrap-icons";
import { update } from "immutable";
import { Popover, Overlay, OverlayTrigger, Tooltip } from "react-bootstrap";

function AddGroup({ user, triggerRefresh, refreshGroup }) {
  const [projects, setProjects] = useState([]);
  const [tenants, setTenants] = useState([]);
  const { roles, userId, tenantId } = React.useContext(ProjectContext);
  const [updatedUser, setUpdatedUser] = useState({});
  const [showPopover, setShowPopover] = useState(null);
  const buttonRefs = React.useRef({});

  let roleOptions = [
    { value: "Reviewer", label: "Reviewer" },
    { value: "ProjectAdmin", label: "Project Admin" },
    { value: "TenantAdmin", label: "Tenant Admin" },
  ];

  if (user.userGroups.length === 0) {
    roleOptions = [{ value: "Developer", label: "Developer" }, ...roleOptions];
  }

  if (roles.includes("ProjectAdmin")) {
    roleOptions = roleOptions.filter((option) => option.value == "Reviewer");
  }
  const [formData, setFormData] = useState({
    role: "",
    project: [],
    tenant: [],
  });

  const [showForm, setShowForm] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({
    role: "",
    project: "",
    tenant: "",
  });
  const [userDetails, setUserDetails] = useState({
    ...user,
    userGroupList: user.userGroupList || [],
  });

  const fetchUserDetails = async () => {
    try {
      const token = await getCurrentToken();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}user?cognitoSub=${user.cognitoSub}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const updatedUser = response.data;
      const userGroupList = updatedUser.userGroups || [];
      const updatedDetails = { ...updatedUser, userGroupList };
      setUserDetails(updatedDetails); // Update local state

      return updatedDetails; // Return for immediate use
    } catch (error) {
      console.error("Error fetching updated user details:", error);
    }
  };
  const fetchProjects = async () => {
    try {
      const token = await getCurrentToken();

      if (!token) {
        console.error("No token found");
        return;
      }

      const params = {
        page: 1,
        size: 10000, // Fetch a large number of projects
        search: "",
        status: "ACTIVE",
        sortColumn: "name",
        sortOrder: "DESC",
        tenantId: tenantId,
      };

      if (roles.includes("ProjectAdmin")) {
        params.userId = userId;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}project/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: params,
        }
      );

      const jsonData = response.data;

      if (Array.isArray(jsonData.content)) {
        // Filter out projects where the user already has a role
        let userProjectNames = [];
        userProjectNames = userDetails.userGroupList.map(
          (group) => group.project
        );

        const availableProjects = jsonData.content.filter(
          (project) => !userProjectNames.includes(project.name)
        );
        setProjects(availableProjects);
      } else {
        console.error("Invalid data format: content is not an array");
      }
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };
  useEffect(() => {
    const fetchTenants = async () => {
      try {
        const token = await getCurrentToken();

        if (!token) {
          console.error("No token found");
          return;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}tenant/list`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              page: 1,
              size: "",
              sortColumn: "name",
              sortOrder: "ASC",
            },
          }
        );

        const jsonData = response.data;

        if (Array.isArray(jsonData.content)) {
          setTenants(jsonData.content);
        } else {
          console.error("Invalid data format: content is not an array");
        }
      } catch (error) {
        console.error("Error fetching project data:", error);
      }
    };

    fetchTenants();
  }, []);
  const handleRoleChange = (selectedOption) => {
    let newFormData = {
      ...formData,
      role: selectedOption || "",
      tenant: [],
      project: [],
    };
    setFormData(newFormData);
    setFieldErrors({ ...fieldErrors, role: "" });
  };
  const handleAddRoleClick = () => {
    setShowForm(true);
    // Fetch updated projects
    fetchUserDetails();
    fetchProjects();
  };
  const handleDeleteGroup = async (group) => {
    try {
      const token = await getCurrentToken();
      const isSingleGroup = userDetails.userGroupList.length === 1;

      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}user/groups`,
        [
          {
            userId: user.id,
            tenantId: group.tenantId,
            projectId: group.projectId,
            groupId: group.groupId,
            userGroupId: group.userGroupId,
            status: "INACTIVE",
          },
        ],
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // If it's the only group, deactivate the user
      // if (isSingleGroup) {
      //   await axios.put(
      //     `${process.env.REACT_APP_BACKEND_URL}user/status`,
      //     {
      //       id: user.id,
      //       status: "INACTIVE",
      //     },
      //     {
      //       headers: {
      //         Authorization: `Bearer ${token}`,
      //       },
      //     }
      //   );
      //   console.log("User deactivated successfully.");
      // }
      // Fetch updated user details and projects to reflect changes
      const updatedDetails = await fetchUserDetails();
      setUserDetails(updatedDetails);

      await fetchProjects();

      // Refresh UI
      triggerRefresh();

      // Reset form and hide
      setFormData({ role: null, project: [], tenant: [tenantId] });
      setShowForm(false);
    } catch (error) {
      console.error("Error deleting group:", error);
    }
  };
  const handleSaveClick = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    let errors = {};
    if (!formData.role) {
      setFieldErrors({ ...fieldErrors, role: "Role is required" });
      return;
    }
    if (
      formData.role &&
      ["Reviewer", "ProjectAdmin"].includes(formData.role.value) &&
      formData.project.length === 0
    ) {
      errors.project = "Project is required";
    }
    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }
    try {
      const token = await getCurrentToken();

      if (!token) {
        console.error("No token found");
        return;
      }
      const groupData = {
        userId: user.id,
        status: "ACTIVE",
      };

      if (formData.role && formData.role.value === "SuperAdmin") {
        groupData.groupId = 1;
      } else if (formData.role && formData.role.value === "Reviewer") {
        groupData.groupId = 2;
        groupData.tenantId = tenantId;
        groupData.projectId = formData.project.value;
      } else if (formData.role && formData.role.value === "ProjectAdmin") {
        groupData.groupId = 3;
        groupData.tenantId = tenantId;
        groupData.projectId = formData.project.value;
      } else if (formData.role && formData.role.value === "TenantAdmin") {
        groupData.groupId = 4;
        groupData.tenantId = tenantId;
      } else if (formData.role && formData.role.value === "Developer") {
        groupData.groupId = 5;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}user/groups`,
        [groupData],
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Fetch updated user details
      fetchUserDetails();
      fetchProjects();
      // Reset form and hide
      setFormData({ role: null, project: [], tenant: [tenantId] });
      setShowForm(false);

      // Trigger external refresh actions
      refreshGroup();
      triggerRefresh();
    } catch (error) {
      console.error("Error adding role:", error);
    }
  };
  useEffect(() => {
    if (userDetails) {
      fetchProjects();
    }
  }, [userDetails]);
  // Function to handle showing the popover
  const handleTogglePopover = (groupId) => {
    if (showPopover === groupId) {
      setShowPopover(null); // Close the popover if clicked again
    } else {
      setShowPopover(groupId); // Show the popover for this group
    }
  };

  const createPopover = (group) => {
    const isSingleGroup = userDetails.userGroupList.length === 1;

    return (
      <Popover id="popover-basic">
        <Popover.Body>
          {isSingleGroup
            ? "Removing the role may disable the user if no other role exist. Are you sure you want to proceed?"
            : "Are you sure you want to delete this group?"}
          <div className="mt-2">
            <button
              onClick={() => {
                if (isSingleGroup) {
                  handleDeleteGroup(group, user.id); // Call deactivate if it's the last group
                } else {
                  handleDeleteGroup(group); // Remove the group normally
                }
                setShowPopover(null);
              }}
              className="btn btn-sm btn-danger me-2 px-3 py-0"
            >
              Yes
            </button>
            <button
              onClick={() => setShowPopover(null)}
              className="btn btn-sm btn-secondary px-3 py-0"
            >
              No
            </button>
          </div>
        </Popover.Body>
      </Popover>
    );
  };
  return (
    <div>
      {!showForm ? (
        <div className="text-end">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="add-role-tooltip">
                {projects.filter(
                  (project) =>
                    !userDetails.userGroupList.some(
                      (group) => group.project === project.name
                    )
                ).length === 0
                  ? "Roles have been assigned to all projects."
                  : "Click to assign a role to a project."}
              </Tooltip>
            }
            container={document.querySelector(".modal")}
          >
            <span>
              <button
                className="btn btn-primary btn-sm px-3"
                onClick={handleAddRoleClick}
                disabled={
                  projects.filter(
                    (project) =>
                      !userDetails.userGroupList.some(
                        (group) => group.project === project.name
                      )
                  ).length === 0
                }
              >
                <PlusCircle /> Add Role
              </button>
            </span>
          </OverlayTrigger>
        </div>
      ) : (
        <>
          <div className="row my-2">
            <div className="col-md-4">
              <Select
                options={roleOptions}
                value={formData.role}
                onChange={handleRoleChange}
                name="roles"
                placeholder="Select Role"
              />
              {fieldErrors.role && (
                <div className="text-danger">{fieldErrors.role}</div>
              )}
            </div>
            {(roles.includes("SuperAdmin") &&
              ["TenantAdmin", "ProjectAdmin", "Reviewer"].includes(
                formData.role?.value
              )) ||
            (roles.includes("TenantAdmin") &&
              ["ProjectAdmin", "Reviewer"].includes(formData.role?.value)) ? (
              <div className="col-md-4">
                <Select
                  options={[
                    {
                      value: tenantId,
                      label:
                        tenants.find((t) => t.id === tenantId)?.name ||
                        "Current Tenant",
                    },
                  ]}
                  value={{
                    value: tenantId,
                    label:
                      tenants.find((t) => t.id === tenantId)?.name ||
                      "Current Tenant",
                  }}
                  isDisabled
                  name="tenants"
                  placeholder="Current Tenant"
                />
              </div>
            ) : null}
            {(roles.includes("SuperAdmin") &&
              ["ProjectAdmin", "Reviewer"].includes(formData.role?.value)) ||
            (roles.includes("TenantAdmin") &&
              ["ProjectAdmin", "Reviewer"].includes(formData.role?.value)) ||
            (roles.includes("ProjectAdmin") &&
              formData.role?.value === "Reviewer") ? (
              <div className="col-md-4">
                <Select
                  options={projects.map((project) => ({
                    value: project.id,
                    label: project.name,
                  }))}
                  value={formData.project}
                  onChange={(selectedOption) => {
                    let newFormData = {
                      ...formData,
                      project: selectedOption,
                    };
                    setFormData(newFormData);
                  }}
                  name="projects"
                  placeholder="Select Project"
                />
                {fieldErrors.project && (
                  <div className="text-danger">{fieldErrors.project}</div>
                )}
              </div>
            ) : null}
          </div>
          <div className="text-end">
            <button
              className="btn btn-secondary btn-sm me-2"
              onClick={() => (
                setShowForm(false),
                (formData.role = ""),
                (formData.project = []),
                (formData.tenant = [])
              )}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary btn-sm"
              onClick={handleSaveClick}
            >
              Add
            </button>
          </div>
        </>
      )}

      <table className="table table-borderless table-striped">
        <thead>
          <tr>
            <th>Role</th>
            <th>Tenant</th>
            <th>Project</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {userDetails.userGroupList.map((group) => (
            <tr key={group.userGroupId}>
              <td>{group.group}</td>
              <td>{group.tenant}</td>
              <td>{group.project}</td>
              <td>
                <button
                  ref={(el) => (buttonRefs.current[group.userGroupId] = el)}
                  onClick={() => handleTogglePopover(group.userGroupId)}
                  type="button"
                  className="border-0 btn-round btn-danger"
                >
                  <Trash3 size={14} />
                </button>
                <Overlay
                  show={showPopover === group.userGroupId}
                  target={buttonRefs.current[group.userGroupId]}
                  placement="top"
                  rootClose
                  onHide={() => setShowPopover(null)}
                >
                  {createPopover(group)}
                </Overlay>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AddGroup;
