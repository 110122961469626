import React, { useState, useEffect, useContext } from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import axios from "axios";
//import "chart.js/auto";
import { getCurrentToken } from "../../services/cognito/cognitoAuth";
import Select from "react-select";
import ProjectContext from "../../ProjectContext";
import { Chart as ChartJS, registerables } from 'chart.js';

ChartJS.register(...registerables, ChartDataLabels);

function IssueCountTitleChart({ title, startDate, endDate }) {
  const [chartData, setChartData] = useState(null);
  const [titles, setTitles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const typeOptions = [
    { value: "SECTION", label: "Section" },
    { value: "SUBSECTION", label: "Subsection" },
    { value: "ISSUE_NATURE", label: "Issue Nature" },
    { value: "ISSUE_TYPE", label: "Issue Type" },
    {
      value: "TEMPLATE_CHANGE_TYPE",
      label: "Template change type",
    },
    { value: "TAG", label: "Tag" },
  ];
  const [filterType, setFilterType] = useState(typeOptions[0]);
  const { tenantId, groupId, userId, projectId, roles } =
    useContext(ProjectContext);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [filterCreatedBy, setFilterCreatedBy] = useState("");
  
  const fetchUsers = async () => {
    try {
      const token = await getCurrentToken();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}user/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page: 1,
            size: "",
            search: "",
            status: "",
            sortColumn: "id",
            sortOrder: "DESC",
            group: "Reviewer",
            projectId: projectId,
            logginGroupId: groupId,
            tenantId: tenantId,
            userId: userId,
          },
        }
      );
      const users = response.data.content;

      setUsers(users);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  const truncateLabel = (label, maxLength) => {
    return label.length > maxLength ? label.substring(0, maxLength) + '...' : label;
  };
  const fetchData = async () => {
    const token = await getCurrentToken();
    try {
      const userRole = roles.includes("Reviewer") ? "Reviewer" : "Other";
      const userIdsParam =
        userRole === "Reviewer"
          ? userId
          : filterCreatedBy || selectedUser?.value;
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}dashboard/issue-count-by-title?`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            userIds: userIdsParam,
            projectId: projectId,
            title: filterType?.value || title,
            startDate: startDate.toISOString().split("T")[0] + " 00:00:00",
            endDate: endDate.toISOString().split("T")[0] + " 00:00:00",
          },
        }
      );

      const data = response.data;
      const maxLength = 10;
      setChartData({
        labels: data.map((item) => truncateLabel(item.title, maxLength)),
        datasets: [
          {
            label: "Count by Title",
            data: data.map((item) => item.count),
            backgroundColor: ["#4e73df"],
            borderWidth: 1,
            //barThickness: 20,
          },
        ],
        fullLabels: data.map((item) => item.title),
        parents: data.map((item) => item.parent), 
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (roles.includes("ProjectAdmin")) {
      fetchUsers();
    }
  }, [projectId, groupId, tenantId, userId, roles]);

  useEffect(() => {
    fetchData();
  }, [
    projectId,
    filterType,
    startDate,
    endDate,
    selectedUser,
    filterCreatedBy,
  ]);
  const fetchTitles = async () => {
    setIsLoading(true);
    try {
      const token = await getCurrentToken();
      const params = {
        page: 1,
        size: "",
        sortColumn: "name",
        sortOrder: "DESC",
        projectId: projectId,
        search: "",
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}issue/title-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: params,
        }
      );
      setTitles(response.data.content);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching titles:", error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchTitles();
  }, [projectId]);
  const colClassName = roles.includes("Reviewer") ? "col-md-12" : "col-md-6";
  return (
    <div>
      <h5 className="fw-bold">Issue Count by Title</h5>
      <div className="row g-2 mb-3">
        <div className={colClassName}>
          <Select
            name="typeOptions"
            options={typeOptions}
            onChange={setFilterType}
            value={filterType}
            className=""
          />
        </div>
        {roles.includes("ProjectAdmin") && (
          <div className={colClassName}>
            <Select
              options={users.map((user) => ({
                value: user.id,
                label: `${user.firstName} ${user.lastName}`,
              }))}
              value={selectedUser}
              isClearable
              placeholder="Select User"
              className=""
              onChange={(selectedOption) => {
                setSelectedUser(selectedOption);
                setFilterCreatedBy(selectedOption ? selectedOption.value : "");
              }}
            />
          </div>
        )}
        
      </div>
      {chartData ? (
        <Bar
          data={chartData}
          options={{
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  title: function(tooltipItems) {
                    const index = tooltipItems[0].dataIndex;
                    return chartData.fullLabels[index]; // Show full label in tooltip
                  },
                  afterTitle: function(tooltipItems) {
                    const index = tooltipItems[0].dataIndex;
                    const parent = chartData.parents[index];
                    return parent ? `Parent: ${parent}` : ''; // Show parent value if not null
                  }
                }
              },
              datalabels: {
                color: '#fff', // Set the color of the label  
                align: 'start',
                anchor: 'end',
              },
            },
            scales: {
              y: {
                ticks: {
                  beginAtZero: true,
                  stepSize: 1, // Ensure the step size is 1 to show integer values
                  callback: function(value) {
                    if (Number.isInteger(value)) {
                      return value;
                    }
                  }
                },
                title: {
                  display: true,
                  text: "Count",
                },
                grid: {
                  display: false, 
                },
              },
              x: {
                title: {
                  display: true,
                  text: "Title",
                },
                grid: {
                  display: false, 
                },
              },
            },
          }}
        />
      ) : (
        <p className="text-center">Loading data...</p>
      )}
    </div>
  );
}

export default IssueCountTitleChart;
