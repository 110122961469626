import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import {
  isLoggedIn,
  getCurrentUser,
  getCurrentToken,
} from "../services/cognito/cognitoAuth";
import MainLayout from "../layout/Layout";
import Users from "../pages/Users";
import FileList from "../pages/FileList";
import Projects from "../pages/Projects";
import ProjectContext from "../ProjectContext";
import axios from "axios";
import FileViewer from "../pages/FileViewer";
import ImportDocuments from "../pages/ImportDocuments";
import Documents from "../pages/Documents";
import FileLog from "../pages/FileLog";
import Dashboard from "../pages/Dashboard";
import TitleList from "../pages/Titles";
import AddIssue from "./issues/AddIssues";
import IssueList from "../pages/IssueList";
import EditIssue from "./issues/EditIssue";
import ModelList from "../pages/ModelList";
import TenantsList from "../pages/Tenants";
import PresetTag from "../pages/PresetTag";
import AddPresetTag from "./tags/AddPresetTag";
import EditPresetTag from "./tags/EditPresetTag";

function AuthenticatedRoutes() {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [cognitoSub, setCognitoSub] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const {
    setProjectId,
    setProjectName,
    setUserId,
    setRoles,
    setGroupId,
    setTenantId,
    setUserGroupId,
    setProjectType,
    roles,
  } = useContext(ProjectContext);

  const navigate = useNavigate();
  const location = useLocation();

  // Fetch user and project info
  const fetchUserAndProjectInfo = async (cognitoSub, token) => {
    try {
      const userResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}user?cognitoSub=${cognitoSub}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const project = userResponse.data.currentProject;
      setProjectId(project.id);
      setProjectName(project.name);
      setUserId(userResponse.data.id);

      // const userRoles = userResponse.data.userGroups
      //   .filter((group) => group.projectId === project.id)
      //   .map((group) => group.group);
      // setRoles(userRoles);
      // Get user groups from userResponse
      const userGroups = userResponse.data.userGroups;
      // Set projectType from the first group in userGroups
      if (userGroups.length > 0) {
        setProjectType(userGroups[0].projectType);
      }
      // If projectId is not available, find the group with the latest lastAccessedOn
      let userRoles;
      if (project.id === null) {
        const lastAccessedGroup = userGroups.reduce(
          (latestGroup, currentGroup) => {
            if (
              !latestGroup ||
              new Date(currentGroup.lastAccessedOn) >
                new Date(latestGroup.lastAccessedOn)
            ) {
              return currentGroup;
            }
            return latestGroup;
          },
          null
        );

        userRoles = lastAccessedGroup ? [lastAccessedGroup.group] : [];
      } else {
        // Filter groups by matching projectId
        userRoles = userGroups
          .filter((group) => group.projectId === project.id)
          .map((group) => group.group);
      }

      // Set the user roles
      setRoles(userRoles);

      const currentProjectGroup = userResponse.data.userGroups.find(
        (group) => group.projectId === project.id
      );
      if (currentProjectGroup) {
        setGroupId(currentProjectGroup.groupId);
        setTenantId(currentProjectGroup.tenantId);
        setUserGroupId(currentProjectGroup.userGroupId);
      }
      // Redirection logic
      if (firstLoad && location.pathname === "/login") {
        const roleRedirects = {
          SuperAdmin: "/users",
          Developer: "/import-documents",
          TenantAdmin: "/users",
          Reviewer: "/dashboard",
          ProjectAdmin: "/dashboard",
        };

        const userRole = Object.keys(roleRedirects).find((role) =>
          userRoles.includes(role)
        );

        if (userRole) {
          navigate(roleRedirects[userRole], { replace: true });
        } else {
          navigate("/dashboard", { replace: true }); // Fallback to dashboard
        }

        setFirstLoad(false); // Set firstLoad to false after the first redirection
      }
    } catch (error) {
      console.error("Error fetching user/project info:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const initializeAuthentication = async () => {
      try {
        const loggedIn = await isLoggedIn();
        setAuthenticated(loggedIn);

        if (loggedIn) {
          const user = await getCurrentUser();
          const token = await getCurrentToken();
          setCognitoSub(user.sub);
          setAccessToken(token);

          await fetchUserAndProjectInfo(user.sub, token);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error("Error initializing authentication:", error);
        setLoading(false);
      }
    };

    initializeAuthentication();
  }, []);
  useEffect(() => {
    if (roles.includes("ProjectAdmin") && location.pathname === "/files") {
      navigate("/documents", { replace: true });
    } else if (
      roles.includes("Reviewer") &&
      location.pathname === "/documents"
    ) {
      navigate("/files", { replace: true });
    }
  }, [roles, location.pathname, navigate]);

  if (loading) return <div>Loading...</div>;

  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route
          path="/dashboard"
          element={
            <RoleProtectedRoute allowedRoles={["Reviewer", "ProjectAdmin"]}>
              <Dashboard />
            </RoleProtectedRoute>
          }
        />

        <Route
          path="/users"
          element={
            <RoleProtectedRoute
              allowedRoles={["SuperAdmin", "ProjectAdmin", "TenantAdmin"]}
            >
              <Users />
            </RoleProtectedRoute>
          }
        />

        <Route path="/files" element={<FileList />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/file-viewer" element={<FileViewer />} />
        <Route path="/import-documents" element={<ImportDocuments />} />
        <Route path="/documents" element={<Documents />} />
        <Route path="/action-log" element={<FileLog />} />
        <Route
          path="/title-list"
          element={
            <RoleProtectedRoute allowedRoles={["ProjectAdmin"]}>
              <TitleList />
            </RoleProtectedRoute>
          }
        />
        <Route path="/issues" element={<IssueList />} />
        <Route path="/add-issue" element={<AddIssue />} />
        <Route path="/edit-issue/:id" element={<EditIssue />} />
        <Route
          path="/model-list"
          element={
            <RoleProtectedRoute allowedRoles={["SuperAdmin"]}>
              <ModelList />
            </RoleProtectedRoute>
          }
        />
        <Route path="/tenants-list" element={<TenantsList />} />
        <Route
          path="/preset-tag"
          element={
            <RoleProtectedRoute allowedRoles={["ProjectAdmin", "Reviewer"]}>
              <PresetTag />
            </RoleProtectedRoute>
          }
        />
        <Route
          path="/add-preset-tag"
          element={
            <RoleProtectedRoute allowedRoles={["ProjectAdmin", "Reviewer"]}>
              <AddPresetTag />
            </RoleProtectedRoute>
          }
        />
        <Route
          path="/edit-preset-tag/:id"
          element={
            <RoleProtectedRoute allowedRoles={["ProjectAdmin", "Reviewer"]}>
              <EditPresetTag />
            </RoleProtectedRoute>
          }
        />
      </Route>

      {/* Fallback */}
      <Route path="*" element={<Navigate to="/dashboard" replace />} />
    </Routes>
  );
}

// Role-protected route component
const RoleProtectedRoute = ({ allowedRoles, children }) => {
  const { roles: userRoles } = useContext(ProjectContext); // Access user roles from ProjectContext
  const navigate = useNavigate();
  const [hasAccess, setHasAccess] = useState(null);

  useEffect(() => {
    if (!userRoles || userRoles.length === 0) {
      setHasAccess(false); // Roles are not yet loaded
      return;
    }

    // Check if the user has access to the current route
    const access = allowedRoles.some((role) => userRoles.includes(role));
    if (!access) {
      // Find the highest priority role and redirect
      const roleRedirects = {
        SuperAdmin: "/users",
        Developer: "/import-documents",
        TenantAdmin: "/users",
        Reviewer: "/dashboard",
        ProjectAdmin: "/dashboard",
      };

      const userRole = Object.keys(roleRedirects).find((role) =>
        userRoles.includes(role)
      );

      if (userRole) {
        navigate(roleRedirects[userRole], { replace: true });
      } else {
        navigate("/dashboard", { replace: true }); // Default fallback
      }
    }

    setHasAccess(access);
  }, [allowedRoles, userRoles, navigate]);

  // Show a loading spinner or block rendering until access is determined
  if (hasAccess === null) {
    return <div>Loading...</div>;
  }

  // If the user has access, render the children
  if (hasAccess) {
    return children;
  }

  // If redirected, render nothing
  return null;
};

export default AuthenticatedRoutes;
