import { useState } from "react";
import { confirmPassword } from "../../services/cognito/cognitoAuth";
import { Link } from "react-router-dom";
import { EyeFill, EyeSlashFill } from "react-bootstrap-icons";

export default function ResetPassword() {
  const [username, setUsername] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      await confirmPassword(username, confirmationCode, newPassword);
      setSuccess(true);
    } catch (err) {
      setError(err.message);
    }
  };

  if (success) {
    return (
      <div>
        <h2>Reset password</h2>
        <p>Your password has been reset successfully!</p>
        <Link to="/login">Login</Link>
      </div>
    );
  }

  return (
    <div>
      <h2 className="fw-bold text-center">Reset Password</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-floating mb-3">
          <input
            type="text"
            placeholder="Username"
            className="form-control rounded-0"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <label htmlFor="floatingInput">Username</label>
        </div>
        <div className="form-floating mb-3">
          <input
            type="text"
            placeholder="Confirmation code"
            className="form-control rounded-0"
            value={confirmationCode}
            onChange={(e) => setConfirmationCode(e.target.value)}
          />
          <label htmlFor="floatingInput">Confirmation code</label>
        </div>
        <div className="form-floating position-relative mb-3">
          <input
            type={showPassword ? "text" : "password"}
            placeholder="New password"
            className="form-control rounded-0"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <label htmlFor="floatingInput">New password</label>
          <span
            className="position-absolute top-50 end-0 translate-middle-y me-3 text-muted"
            onClick={() => setShowPassword(!showPassword)}
            style={{ cursor: "pointer" }}
          >
            {showPassword ? <EyeSlashFill size={20} /> : <EyeFill size={20} />}
          </span>
        </div>
        <div className="alert alert-info">
          Password must be at least 8 characters long and contain at least one
          uppercase letter, one lowercase letter, one number, and one special
          character.
        </div>
        <button type="submit" className="btn btn-primary w-100 mb-2">
          Submit
        </button>
      </form>
      {error && <p className="text-danger">{error}</p>}
    </div>
  );
}
