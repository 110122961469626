import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { getCurrentToken } from "../services/cognito/cognitoAuth";
import {
  Download,
  Search,
  EyeFill,
  ViewList,
  JournalText,
} from "react-bootstrap-icons";
import ProjectContext from "../ProjectContext";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Pagination from "../components/Pagination";
import Select from "react-select";
import { formatDate } from "../utils";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function FileList() {
  const [files, setFiles] = useState([]);
  const [totalPageSize, setTotalPageSize] = useState(1);
  const [searchQuery, setSearchQuery] = useState(() => {
    return localStorage.getItem("searchQuery") || "";
  });
  const pageSize = 10;
  const { projectId, projectName, roles, userId, projectType } =
    useContext(ProjectContext);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedStatus, setSelectedStatus] = useState(() => {
    const savedStatus = localStorage.getItem("selectedStatus");
    return savedStatus
      ? JSON.parse(savedStatus)
      : [{ value: "ASSIGNED", label: "ASSIGNED" }];
  });
  //const { fileId } = location.state || {};
  const [totalListCount, setTotalListCount] = useState(0);
  const navigationState = JSON.parse(localStorage.getItem("navigationState"));
  const fileId = navigationState ? navigationState.fileId : null;
  const [currentPage, setCurrentPage] = useState(() => {
    const savedPage = JSON.parse(localStorage.getItem("fileCurrentPage"));
    return savedPage || 1;
  });
  useEffect(() => {
    localStorage.setItem("selectedStatus", JSON.stringify(selectedStatus));
    localStorage.setItem("fileCurrentPage", JSON.stringify(currentPage));
  }, [selectedStatus, currentPage]);
  useEffect(() => {
    if (projectId && searchQuery !== null) {
      fetchFiles();
    }
  }, [currentPage, projectId, selectedStatus, searchQuery]);

  const fetchFiles = async () => {
    setIsLoading(true); // Set loading to true
    try {
      const token = await getCurrentToken();

      if (!token) {
        console.error("No token found");
        return;
      }
      const statusString = selectedStatus
        .map((option) => option.value)
        .join(",");
      let params = {
        page: currentPage,
        size: pageSize,
        search: searchQuery,
        sortColumn: "startedAt",
        sortOrder: "DESC",
        status: statusString,
        projectId: projectId,
        reviewerId: userId,
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}fileLog/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: params,
        }
      );

      const jsonData = response.data;

      if (Array.isArray(jsonData.content)) {
        setFiles(jsonData.content);
      } else {
        console.error("Invalid data format: content is not an array");
      }
      const pageInfo = response.data.pageInfo;
      let adjustedTotalPageSize = pageInfo.totalPageSize;
      if (pageInfo.totalElements > 10 && pageInfo.totalElements % 10 !== 0) {
        adjustedTotalPageSize += 1;
      }
      setTotalPageSize(adjustedTotalPageSize);
      const totalList = response.data.pageInfo.totalElements;
      setTotalListCount(totalList);
    } catch (error) {
      console.error("Error fetching file data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getStatusClassName = (status) => {
    return status === "UPLOADED"
      ? "badge text-bg-secondary"
      : status === "FAILED"
      ? "badge text-bg-danger"
      : status === "PROCESSING"
      ? "badge text-bg-warning"
      : status === "ASSIGNED"
      ? "badge text-bg-info"
      : "badge text-bg-success";
  };
  const handleViewFile = async (
    filePathJson,
    processedFileURL,
    id,
    status,
    name,
    currentPage
  ) => {
    navigate("/file-viewer", {
      state: {
        filePathJson,
        processedFileURL,
        id,
        status,
        currentPage,
        selectedStatus,
        name,
      },
    });
    try {
      const token = await getCurrentToken();

      if (!token) {
        console.error("No token found");
        return;
      }
      const stausResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}file-upload/save-action-log
        `,
        {
          fileId: id,
          userId: userId,
          status: "VIEWED",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error("Error viewing file:", error);
    }
  };
  const handleViewPdf = async (
    filePathJson,
    processedFileURL,
    fileURL,
    id,
    status,
    name,
    currentPage
  ) => {
    navigate("/file-viewer", {
      state: {
        filePathJson,
        processedFileURL,
        fileURL,
        id,
        status,
        currentPage,
        selectedStatus,
        name,
      },
    });
    try {
      const token = await getCurrentToken();

      if (!token) {
        console.error("No token found");
        return;
      }
      const stausResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}file-upload/save-action-log
        `,
        {
          fileId: id,
          userId: userId,
          status: "VIEWED",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error("Error viewing file:", error);
    }
  };
  const statuses = [
    "ASSIGNED",
    "ACTIVE",
    "ISSUE",
    "NOISSUE",
    "RESOLVED",
    "REVIEW_COMPLETED",
  ];
  const statusLabels = {
    ASSIGNED: "Assigned",
    ACTIVE: "Active",
    ISSUE: "Issue",
    NOISSUE: "No Issue",
    RESOLVED: "Resolved",
    REVIEW_COMPLETED: "Review Completed",
  };
  const options = statuses.map((status) => ({
    value: status,
    label: statusLabels[status],
  }));
  const handleViewList = async (id, currentPage) => {
    navigate("/issues", {
      state: { id, currentPage },
    });
  };
  const handleViewLogs = async (id, currentPage) => {
    navigate("/action-log", {
      state: { id, currentPage },
    });
  };
  const handleSearchQueryChange = (e) => {
    const newSearchQuery = e.target.value;
    setSearchQuery(newSearchQuery);
    localStorage.setItem("searchQuery", newSearchQuery); // Store it immediately
    setCurrentPage(1);
  };

  return (
    <div className="container-fluid flex-grow-1">
      <div className="d-flex align-items-center justify-content-between py-3">
        <h2 className="fw-bold mb-0">
          Documents{" "}
          {!roles.includes("SuperAdmin") && projectName && (
            <span className="text-primary fs-3">({projectName})</span>
          )}
        </h2>
        <div className="d-flex align-items-center flex-1">
          <Select
            options={options}
            onChange={(selectedOptions) => {
              setSelectedStatus(selectedOptions || []);
              setCurrentPage(1);
            }}
            value={selectedStatus}
            isMulti
            placeholder="Select Status"
            className="me-2 w-100"
          />
          <div className="input-group flex-nowrap">
            <span
              className="input-group-text bg-transparent"
              id="addon-wrapping"
            >
              <Search />
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Search List"
              value={searchQuery}
              onChange={handleSearchQueryChange}
            />
          </div>
        </div>
      </div>
      <div className="table-responsive border rounded p-2">
        {files.length > 0 ? (
          <>
            {isLoading ? (
              <p>Loading...</p> // Display loading indicator while files are being fetched
            ) : (
              <table className="table table-borderless vertical-align-middle">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>File Name</th>
                    <th>Timestamp</th>
                    <th>Status</th>
                    <th>Active Issue Count</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {files.map((file) => (
                    <tr
                      key={file.id}
                      className={fileId === file.id ? "active-row" : ""}
                    >
                      <td>{file.id}</td>
                      <td width="40%">{file.name}</td>
                      <td>
                        {file.timeStamp ? formatDate(file.timeStamp) : ""}
                      </td>
                      <td>
                        <span className={getStatusClassName(file.status)}>
                          {file.status}
                        </span>
                      </td>
                      <td>{file.issueCount === 0 ? "-" : file.issueCount}</td>
                      <td>
                        {projectType === "PREVIEW_PROJECT" && (
                          <button
                            onClick={() =>
                              handleViewPdf(
                                file.filePathJson,
                                file.processedFileURL,
                                file.fileURL,
                                file.id,
                                file.status,
                                file.name,
                                currentPage
                              )
                            }
                            className="btn btn-sm btn-outline-primary px-3 me-2"
                          >
                            <EyeFill size={18} />
                          </button>
                        )}
                        {file.filePathJson && file.filePathJson !== "null" && (
                          <>
                            <button
                              onClick={() =>
                                handleViewFile(
                                  file.filePathJson,
                                  file.processedFileURL,
                                  file.id,
                                  file.status,
                                  file.name,
                                  currentPage
                                )
                              }
                              className="btn btn-sm btn-outline-primary px-3 me-2"
                            >
                              <EyeFill size={18} />
                            </button>
                          </>
                        )}
                        {file.status !== "ASSIGNED" && (
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>View Issues</Tooltip>}
                          >
                            <button
                              className="btn btn-sm btn-outline-primary px-3 me-2"
                              onClick={() =>
                                handleViewList(file.id, currentPage)
                              }
                            >
                              <ViewList size={18} />
                            </button>
                          </OverlayTrigger>
                        )}
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>View Logs</Tooltip>}
                        >
                          <button
                            className="btn btn-sm btn-outline-primary px-3"
                            onClick={() => handleViewLogs(file.id, currentPage)}
                          >
                            <JournalText size={18} />
                          </button>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            <Pagination
              currentPage={currentPage}
              totalPageSize={totalPageSize}
              onPageChange={handlePageChange}
              totalListCount={totalListCount}
              pageSize={pageSize}
            />
          </>
        ) : (
          <p className="text-center fs-5 mt-4">No files in current project</p>
        )}
      </div>
    </div>
  );
}

export default FileList;
